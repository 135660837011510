import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import uniqid from 'uniqid'
import { graphql, useStaticQuery } from 'gatsby'
import SingleArticleTeaser from '~components/globals/SingleArticleTeaser'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'

export const Topic = ({
  pageContext: {
    page: { data }
  }
}) => {

  // if (prismicInterviewTeaser) {
  //   eyebrow = 'interviews'
  //   headline = prismicInterviewTeaser.subline
  //   image = prismicInterviewTeaser.image
  // } else if (prismicInfographicTeaser) {
  //   eyebrow = 'infographics'
  //   headline = prismicInfographicTeaser.subline
  //   image = prismicInfographicTeaser.image
  // } else if (prismicCuratedNewsTeaser) {
  //   eyebrow = 'insights'
  //   headline = prismicCuratedNewsTeaser.subheadline
  //   image = prismicCuratedNewsTeaser.image
  // }
  const [filteredArticles, setFilteredArticles] = useState([])

  const { description, eyebrow, headline, image, topic } = data

  const { allPrismicArticle } = useStaticQuery(graphql`
    query topicPageQuery {
      allPrismicArticle(sort: {fields: data___date, order: DESC}) {
        nodes {
          data {
            image {
              alt
              url
            }
            headline {
              text
            }
            topics {
              topic {
                slug
              }
            }
            type
          }
          uid
        }
      }
    }
  `)

  useEffect(() => {
    const array = []
    allPrismicArticle.nodes.forEach(el => {
      el.data.topics.forEach(elTopic => {
        if (!array.includes(el) && elTopic?.topic?.slug?.includes(topic.slug)) {
          array.push(el)
        }
      })
    })
    setFilteredArticles(array)
  }, [])

  return (
    <Container extraClassName={styles.Topic}>
      <div className={styles.header}>
        <div className={styles.image}>
          <img src={image.url} alt={image.alt} />
        </div>
        <div className={styles.headerText}>
          <h4 className={styles.eyebrow}>
            {eyebrow.html && parse(eyebrow.html)}
          </h4>
          <h2 className={styles.title}>
            {headline.html && parse(headline.html)}
          </h2>
          <div data-html className={styles.content}>
            {description.html && parse(description.html)}
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {filteredArticles.length && filteredArticles.map(article => (
          <SingleArticleTeaser
            data={article.data}
            key={uniqid()}
            uid={article.uid}
          />
        ))}
      </div>
    </Container>
  )
}

Topic.propTypes = {
  pageContext: PropTypes.shape({
    page: PropTypes.shape({
      data: PropTypes.shape({
        intro_text: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        description: PropTypes.PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        headline: PropTypes.PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        eyebrow: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        topic: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
}

export default Topic
